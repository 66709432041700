<template>
  <div>
    <breadcrumb :items="breadcrumbs" />
    <hero :has-right-visible="false">
      Games
    </hero>
    <section class="section is-main-section">
      <card
        class="has-table has-mobile-sort-spaced"
        title="Games"
        icon="google-controller"
      >
        <Table 
          :data="games"
          :default-sort="['distribution_id', 'desc']"
        >
          <template #columns>
            <b-table-column
              v-slot="props"
              label="ID"
              field="distribution_id"
              width="90"
              sortable
              searchable
            >
              {{ props.row.distribution_id }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Title"
              field="title"
              sortable
              searchable
            >
              {{ props.row.title }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Published"
              field="published_at"
              sortable
            >
              <b-icon
                v-if="isScheduled(props.row.published_at)"
                :title="props.row.published_at"
                icon="calendar-range" 
              />
              <b-icon
                v-else-if="isActive(props.row.published_at)"
                :title="props.row.published_at"
                icon="check" 
              />
            </b-table-column>
            <b-table-column
              v-slot="props"
              custom-key="actions"
              cell-class="is-actions-cell"
            >
              <div class="buttons is-right">
                <router-link 
                  :to="{ name: 'GamesEdit', params: { game: props.row.distribution_id } }" 
                  class="button is-small is-primary"
                >
                  <b-icon 
                    icon="pencil-outline" 
                    size="is-small"
                  />
                </router-link>
              </div>
            </b-table-column>
          </template>
        </Table>
      </card>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hero from "@/components/Hero";
import Card from "@/components/Card";
import Table from "@/components/Table";

export default {
  name: 'Games',

  components: {
    Breadcrumb,
    Hero,
    Card,
    Table,
  },

  data() {
    return {
      breadcrumbs: [
        'Admin', 
        'Games',
      ],
    };
  },

  computed: {
    ...mapGetters('data', ['games']),
  },

  methods: {
    isScheduled(publishedAt) {
      return new Date(publishedAt) > new Date();
    },

    isActive(publishedAt) {
      return new Date(publishedAt) < new Date();
    },
  },
};
</script>
